import useKeyboardCapture from './useKeyboardCapture';
import { useEffect, useState } from 'react';

export function useKeyboardHighlight<T>(props: {
  options: T[];
  enabled?: boolean;
  onSelect: (args: { value: T; index: number; reset: () => void }) => void;
  onEscape?: () => void;
  group?: keyof T;
  leftRight?: boolean;
}): { highlighted: number; selected: T | null; reset: () => void } {
  const [highlighted, setHighlighted] = useState(-1);
  const selected = highlighted > -1 ? props.options[highlighted] || null : null;

  const reset = (): void => {
    setHighlighted(-1);
  };

  useEffect(() => {
    const options = props.options;

    if (options.length === 1 && highlighted !== 0) {
      // make sure if only one option, it is pre-selected
      setHighlighted(0);
    } else if (options.length > 1 && highlighted > options.length - 1) {
      // if the option list is made smaller, highlight first option
      setHighlighted(0);
    }
  }, [props.options]);

  useKeyboardCapture({
    enabled: props.enabled,
    onEscape() {
      if (props.onEscape) {
        props.onEscape();
      }

      reset();
    },
    onTab: reset,
    onLeft() {
      if (!props.leftRight && !props.group) {
        return;
      }

      setHighlighted((h) => Math.max(0, h - 1));

      // prevent content from scrolling (default arrow left behavior)
      return props.enabled;
    },
    onRight() {
      if (!props.leftRight && !props.group) {
        return;
      }

      setHighlighted((h) => Math.min(props.options.length - 1, h + 1));

      // prevent content from scrolling (default arrow right behavior)
      return props.enabled && props.options.length > 0;
    },
    onDown() {
      if (props.leftRight && !props.group) {
        return;
      }

      const options = props.options;
      const selectedOption = selected;
      const group = props.group;

      let targetIndex = Math.min(options.length - 1, highlighted + 1);

      if (group && selectedOption) {
        const currentGroup = options.filter((item) => item[group] === selectedOption[group]);
        const groupOffset = currentGroup.indexOf(selectedOption);

        const next = options.filter((item, i) => i > highlighted && item[group] !== selectedOption[group]);
        const nextGroupItem = next[0];

        if (nextGroupItem) {
          const nextGroup = next.filter((p) => p[group] === nextGroupItem[group]);

          targetIndex = options.indexOf(nextGroup[groupOffset] || nextGroupItem);
        } else {
          return;
        }
      }

      setHighlighted(targetIndex);

      // prevent content from scrolling (default arrow down behavior)
      return props.enabled && props.options.length > 0;
    },
    onUp() {
      if (props.leftRight && !props.group) {
        return;
      }

      const options = props.options;
      const selectedOption = selected;
      const group = props.group;

      let targetIndex = Math.max(0, highlighted - 1);

      if (group && selectedOption) {
        const currentGroup = options.filter((item) => item[group] === selectedOption[group]);
        const groupOffset = currentGroup.indexOf(selectedOption);

        const previous = options.filter((item, i) => i < highlighted && item[group] !== selectedOption[group]);
        const previousGroupItem = previous[previous.length - 1];

        if (previousGroupItem) {
          const previousGroup = previous.filter((p) => p[group] === previousGroupItem[group]);

          targetIndex = options.indexOf(previousGroup[groupOffset] || previousGroupItem);
        } else {
          return;
        }
      }

      setHighlighted(targetIndex);

      // prevent content from scrolling (default arrow up behavior)
      return props.enabled && props.options.length > 0;
    },
    onEnter() {
      const index = highlighted;

      if (index < 0) {
        return;
      }

      const value = props.options[highlighted];

      if (value) {
        props.onSelect({ value, index, reset });
      }
    },
  });

  useEffect(() => {
    const trackMouse = (): void => {
      // prevent mouse from causing rollover state along side highlighted state
      setHighlighted(-1);
    };

    window.addEventListener('mousemove', trackMouse);

    return () => {
      window.removeEventListener('mousemove', trackMouse);
    };
  }, []);

  return {
    highlighted,
    selected,
    reset,
  };
}
